/*
 * @Author: your name
 * @Date: 2021-06-02 10:55:38
 * @LastEditTime: 2021-07-06 10:53:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /call_manage_system_vue/src/store/getters.js
 */
const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  user_id: (state) => state.user.user_id,
  phone_number: (state) => state.user.phone_number,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  permission_routes: (state) => state.permission.routes,
  topbarRouters: (state) => state.permission.topbarRouters,
  defaultRoutes: (state) => state.permission.defaultRoutes,
  sidebarRouters: (state) => state.permission.sidebarRouters,
  userType: (state) => state.user.userType,
  companyID: (state) => state.user.companyID,
  companyName: (state) => state.user.companyName,
  workPhone: (state) => state.user.workPhone,
  deptId: (state) => state.user.deptId,
  socketMsgs:(state) => state.websocket.msg,
};
export default getters;
