import JsSIP from "jssip";
import bus from "./bus";

var ua = null;
var currentSession; // 当前会话
var peer;
var stream; // 流媒体

// 初始化配置
function initConfig(data) {
    // Create our JsSIP instance and run it:
    const socket = new JsSIP.WebSocketInterface(
        `wss://${data.url}:${data.port}`
    );
    const configuration = {
        sockets: [socket],
        uri: `sip:${data.username}@${data.url}`, // 分机号注册 格式 sip: + 分机号码 + @ + FS注册地址
        authorization_user: data.username, // 授权用户
        password: data.password, // 密码
        register: true, // 自动注册
        connection_recovery_max_interval: 60, // 链接恢复的最大间隔
        connection_recovery_min_interval: 5, // 链接恢复的最小间隔
    };
    return configuration;
}

// 初始化jssip
function jssipInit(data) {
    JsSIP.C.SESSION_EXPIRES = 120; // FreeSWITCH 默认设置要求 Session Expires 不低于120 低于120打不出电话
    JsSIP.C.MIN_SESSION_EXPIRES = 120;
    const configuration = initConfig(data); // 配置参数
    ua = new JsSIP.UA(configuration); // 创建对象
    addRegisterMethod(ua); // 添加监听方法
    ua.start(); // 链接
    console.log(ua)
    return ua;
}
function disconnect() {
    if (ua) {
        ua.stop({
            status_code: JsSIP.C.causes.NORMAL_TERMINATION,
            reason_phrase: '正常关闭'
        });
        ua = null;
    }
}
// 打电话
function call(data) {
    // 这一步也是添加监听，不过已经使用在addRegisterMethod方法中添加了这里为空就行
    const eventHandlers = {
        // 呼叫中
        // progress: function (e) {
        //   console.log('call is in progress')
        // },
        // 失败
        // failed: function (e) {
        //   console.log('call failed: ', e)
        // },
        // 结束
        // ended: function (e) {
        //   console.log('call ended : ', e)
        // }
        // 呼叫确认
        // confirmed: function (e) {
        //   console.log('call confirmed')
        // }
    };
    const options = {
        eventHandlers: eventHandlers,
        mediaConstraints: {
            audio: true,
            video: false,
        },
    };
    // console.log('呼叫:' + data.phone)
    ua.call(`sip:${data.phone}@${data.url}`, options);
}

// jssip监听方法
function addRegisterMethod(ua) {
    // 接线中
    ua.on("connecting", (res) => {
        // console.log('接线中', res)
    });
    // 连线中
    ua.on("connected", (res) => {
        // console.log('连线中', res)
        bus.$emit("sessionData", { state: "connected", data: res });
    });
    // 取消连线
    ua.on("disconnected", (res) => {
        console.log("取消连线", res);
        bus.$emit("sessionData", { state: "disconnected", data: res });
    });
    // 注册成功
    ua.on("registered", (res) => {
        console.log("注册成功", res);
        bus.$emit("sessionData", { state: "registered", data: res }); // 这里使用bus将数据传递到对应的监听文件进行处理
    });
    // 注册失败
    ua.on("registrationFailed", (res) => {
        // console.log('注册失败', res)
    });
    // 注册即将失效(重新注册)
    ua.on("registrationExpiring", (res) => {
        console.log("重新注册", res);
    });
    // 注销回调
    ua.on("unregistered", (res) => {
        console.log("解除注册", res);
        bus.$emit("sessionData", { state: "unregistered", data: res }); // 这里使用bus将数据传递到对应的监听文件进行处理
    });

    // 会话
    ua.on("newRTCSession", function (data) {
        const { session, request, originator } = data;
        currentSession = session;

        console.log(currentSession)
        // 呼叫中 打电话与接电话的呼叫都走这里
        currentSession.on("progress", (res) => {
            if (originator === "remote") {
                console.log("电话过来拉~~~~~~~~~··", res);
                // console.log('我接听了', res)
                bus.$emit("sessionData", {
                    state: "progress-remote",
                    data: request,
                });
                playRingMedia(); // 这里可以加一个判断，如果是监听的时候不需要播放音乐
            } else {
                console.log("接听事件在progress中触发", res);
                bus.$emit("sessionData", { state: "progress", data: res });
                streamingMediaPlayer(); // 添加媒体流 这时候开启音频
            }
        });
        // 对等连接事件触发
        currentSession.on("peerconnection", (data) => {
            console.log("对等连接事件触发,peerconnection", data);
            bus.$emit("sessionData", { state: "peerconnection", data });
        });
        // 交换sdp信令事件触发
        currentSession.on("sdp", (res) => {
            console.log("交换sdp信令事件触发,sdp", res);
            bus.$emit("sessionData", { state: "sdp", data: res });
        });
        // 对等连接建立(通话连线时候触发)
        currentSession.on("connecting", (data) => {
            peer = currentSession._connection;
            console.log("对等连接建立,connecting", peer, data);
            bus.$emit("sessionData", { state: "connecting", data });
        });
        // 通话接受时候触发
        currentSession.on("accepted", (res) => {
            console.log("通话接受时候触发", res);
            bus.$emit("sessionData", { state: "accepted", data: res });
        });
        // 通话失败事件触发
        currentSession.on("failed", (res) => {
            console.log("通话失败事件触发--- failed", res);
            stopPlayRingMedia(); // 关闭来电振铃
            bus.$emit("sessionData", { state: "failed", data: res });
        });
        currentSession.on("reinvite", (res) => {
            // openLocalCamera()
            console.log("重新协商事件触发", res);
            if (currentSession._connection.getLocalStreams().length > 0) {
                // 接听后，判断localStream
            }
            if (currentSession?._connection.getRemoteStreams().length > 0) {
            }
        });
        // 呼叫确认
        currentSession.on("confirmed", (res) => {
            console.log("呼叫确认--设置媒体流到音视频中,confirmed", res);
            bus.$emit("sessionData", { state: "confirmed", data: res });
            if (originator === "local") {
                streamingMediaPlayer(); // 呼入时没有添加媒体流，这时候添加媒体流
            }
            console.log('originator-------', originator)
        });
        // 通话结束
        currentSession.on("ended", (res) => {
            console.log("通话结束--- ended", res);
            bus.$emit("sessionData", { state: "ended", data: res });
        });
    });
}
// 挂断
function hangUp() {
    stopPlayRingMedia(); // 关闭放来电振铃
    ua.terminateSessions();
}
// 接听
function answer() {
    var options = {
        mediaConstraints: {
            audio: true,
            video: false,
            mandatory: { maxWidth: 640, maxHeight: 360 },
        },
    };
    currentSession.answer(options); // 接听来电
}
// 添加媒体流
function streamingMediaPlayer() {
    console.log('streamingMediaPlayer')
    stopstreamingMediaPlayer(); // 先关闭媒体流
    stream = new MediaStream();
    const receivers = currentSession.connection?.getReceivers();
    console.log(currentSession)
    if (receivers) {
        receivers.forEach((receiver) => stream.addTrack(receiver.track));
    }
    console.log(stream)
    // 这里创建一个audio标签隐藏，添加媒体流，添加到界面id为ringMediaAudioId的标签中
    var ringAudio = document.getElementById("ringMediaAudioId");
    ringAudio = document.createElement("audio");
    ringAudio.id = "ringMediaAudioId";
    ringAudio.hidden = true;
    ringAudio.srcObject = stream; // 添加媒体流
    console.log(ringAudio)
    var box = document.getElementById("ringMediaAudioBox");
    box.appendChild(ringAudio); // 添加到box中
    ringAudio.play(); // 播放
}
// 停止播放媒体流
function stopstreamingMediaPlayer() {
    var ringAudio = document.getElementById("ringMediaAudioId");
    if (ringAudio) {
        var box = document.getElementById("ringMediaAudioBox");
        box.removeChild(ringAudio);
    }
}
// 播放来电振铃
function playRingMedia() {
    stopPlayRingMedia();
    var ringAudio = document.getElementById("cheerMusic"); // 这是界面存在的audio标签，隐藏了的，里面的音乐可以自己找，主要用于呼入时响铃
    ringAudio && ringAudio.play();
}
// 停止播放来电振铃
function stopPlayRingMedia() {
    var cheerMusic = document.getElementById("cheerMusic");
    cheerMusic && cheerMusic.load();
}
/**
 * 二次拨号
 * @param dtmfNumber 二次拨号号码
 * 注意功能是打通电话后，在按键时对方可接收到
 */
function dtmf(dtmfNumber) {
    console.log(dtmfNumber);
    const options = {
        transportType: "RFC2833", // dtmf类型,默认是info
    };
    currentSession.sendDTMF(dtmfNumber, options);
}
const sip = {
    jssipInit: jssipInit, // 初始化
    call: call, // 拨号
    answer: answer, // 接听
    hangUp: hangUp, // 挂断
    dtmf: dtmf, // 二次拨号
    close: disconnect,
    sipError: {
        10001: '请求未携带X - AccessToken',
        11101: '当前ecp号码未开⼾',
        11102: '当前ecp号码处于停机状态',
        11103: '当前ECP号码当天呼叫时⻓已经达到上限',
        11104: '当前ecp号码不属于请求⽅',
        11105: 'ECP号码和主叫号码不⼀致',
        11106: '你的ECP号码尚未激活，请激活后再试',
        11107: '当前ECP号码半⼩时拨打次数达到上限',
        11108: '当前ECP号码每⽇拨打次数达到上限',
        11109: 'ECP号码⿊名单',
        11110: '当前时间段⽆法调⽤',
        11111: '通话时⻓剩余资源不⾜，⽆法发起呼叫',
        11112: '被叫⿊名单',
        11113: '被叫号码当⽇被拨打次数达到上限',
        11114: '被叫号码当⽇全平台被拨打次数达到上限',
        11115: '当前appId不属于调⽤的账号',
        11116: '当前ip不在配置的应⽤⽩名单内',
        11117: 'access token失效',
        11118: 'access token关联的app应⽤不存在',
        11119: '号码能力不够，如不能直连网关、双呼等',
        11120: '当前 ECP 号码发送短信次数达到上限',
        11121: '只允许国内号码或特定号码',
        11122: '风控限制不允许激活，如非浙江号码不允许激活、虚商号码不允许激活等',
        11123: '被叫号码不允许和主叫、ecp 号码相同',
        11124: '被叫号码校验无 H 码',
        11125: '当前企业已达呼叫上限，请稍后再试：如呼叫会话并发达到最大值',
        11130: '调⽤呼叫中⼼⻛控失败：如短时内被叫多次，hcode误等'
    },
    sipCode: {
        100: '正在尝试',
        180: '正在拨打',
        181: '正被转接',
        182: '正在排队',
        183: '通话进展',
        300: '多项选择',
        301: '被永久迁移',
        302: '被暂时迁移',
        305: '使用代理服务器',
        380: '替代服务',
        400: '呼叫不当',
        401: '未经授权：只供注册机构使用，代理服务器应使用代理服务器授权407',
        402: '要求付费（预订为将来使用)',
        403: '被禁止的',
        404: '未发现：未发现用户',
        405: '不允许的方法',
        406: '不可接受',
        407: '需要代理服务器授权',
        408: '呼叫超时：在预定时间内无法找到用户, 客户端久响没有回应，返回408给服务器',
        410: '已消失：用户曾经存在，但已从此处消失',
        413: '呼叫实体过大',
        414: '呼叫URI过长',
        415: '不支持的媒体类型',
        416: '不支持的URI方案',
        420: '不当扩展：使用了不当SIP协议扩展，服务器无法理解该扩展',
        421: '需要扩展',
        423: '时间间隔过短',
        480: '暂时不可使用',
        481: '通话 / 事务不存在',
        482: '检测到循环',
        483: '跳数过多',
        484: '地址不全',
        485: '模糊不清',
        486: '此处太忙',
        487: '呼叫被终止: 服务器呼叫超时，主动取消呼叫请求',
        488: '此处不可接受',
        491: '呼叫待批',
        493: '无法解读：无法解读S/ MIME文体部分',
        500: '服务器内部错误',
        501: '无法实施：SIP呼叫方法在此处无法实施',
        502: '不当网关',
        503: '服务不可使用',
        504: '服务器超时',
        505: '不支持该版本：服务器不支持SIP协议的这个版本',
        513: '消息过长',
        600: '各处均忙',
        603: '拒绝',
        604: '无处存在',
        606: '不可使用',
    }
};
export default sip;
