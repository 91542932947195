var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            size: "medium",
            "label-width": "100px",
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("你好")]),
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _c("span", { staticStyle: { color: "#1890ff" } }, [
              _vm._v(_vm._s(_vm.companyName) + "-" + _vm._s(_vm.inviterName)),
            ]),
            _vm._v(" 邀请你进行注册 "),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: "15" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "field102" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "请输入姓名", clearable: "" },
                    model: {
                      value: _vm.formData.field102,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "field102", $$v)
                      },
                      expression: "formData.field102",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号", prop: "mobile" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: {
                  placeholder: "请输入手机号",
                  maxlength: 11,
                  "show-word-limit": "",
                  clearable: "",
                  "prefix-icon": "el-icon-mobile",
                },
                model: {
                  value: _vm.formData.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "mobile", $$v)
                  },
                  expression: "formData.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "短信验证码", prop: "field105" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: { placeholder: "请输入短信验证码", clearable: "" },
                model: {
                  value: _vm.formData.field105,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "field105", $$v)
                  },
                  expression: "formData.field105",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "密码", prop: "field103" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: { placeholder: "请输入密码", clearable: "" },
                model: {
                  value: _vm.formData.field103,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "field103", $$v)
                  },
                  expression: "formData.field103",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "确认密码", prop: "field104" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: {
                  placeholder: "再次确认密码",
                  clearable: "",
                  "show-password": "",
                },
                model: {
                  value: _vm.formData.field104,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "field104", $$v)
                  },
                  expression: "formData.field104",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { size: "large" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("提交")]
              ),
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("重置"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "el-login-footer" }, [
      _c("span", [
        _vm._v(
          "Copyright ©2013-2023浙江网看创享科技有限公司 企鱼 | 浙ICP备18020380号-4"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }