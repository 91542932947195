<template>
  <el-dialog :title="title" :visible.sync="open" width="1000px" append-to-body>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">

  
          <el-form-item label="上次联系时间">
            <el-date-picker
              v-model="form.lastContact"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择时间"
            >
            </el-date-picker>
          </el-form-item>
      
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="open = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addData, updateData } from "@/api/custom/index";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 弹框
      rules: {
        customerName: [
          { required: true, message: "公司名称不能为空", trigger: "blur" },
        ],
        contactAddress: [
          { required: true, message: "联系地址不能为空", trigger: "blur" },
        ],
        linkMan: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
        ],

        grouping: [
          { required: true, message: "请选择资源分组", trigger: "change" },
        ],
        salesProcess: [
          { required: true, message: "请选择销售进度", trigger: "change" },
        ],
        customerType: [
          { required: true, message: "请选择客户类型", trigger: "change" },
        ],
      },
      form: {
        customerName: "",
        contactAddress: "",
        linkMan: "",
        mobile: "",
        alternatePhone: "",
        grouping: "",
        owner: "",
        salesProcess: "",
        customerType: "",
        lastContact: "",
        creationDate: "",
        nextContact: "",
      },
      open: false,
      title: "",
    };
  },
  methods: {
    // 显示弹框
    show(row) {
      this.open = true;
      if (row) {
        this.title = "修改资源数据";
        this.form = {
          id: row.id,
          customerName: row.customerName,
          contactAddress: row.contactAddress,
          linkMan: row.linkMan,
          mobile: row.mobile,
          alternatePhone: row.alternatePhone,
          grouping: row.grouping,
          owner: row.owner,
          salesProcess: row.salesProcess,
          customerType: row.customerType,
          lastContact: row.lastContact,
          nextContact: row.nextContact,
        };
      } else {
        this.title = "新增资源数据";
        this.form = {};
      }
    },

    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            updateData(this.form).then((response) => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.$emit("ok");
            });
          } else {
            addData(this.form).then((response) => {
              this.msgSuccess("新增成功");
              this.open = false;
              this.$emit("ok");
            });
          }
        }
      });
    },
  },
};
</script>
