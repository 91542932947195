<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="16">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-card class="box-card" style="height: 224px">
              <div slot="header" class="clearfix">
                <span>{{ detail.customerName }}</span>
                <el-button
                  icon="el-icon-edit"
                  style="float: right; padding: 3px 0"
                  type="text"
                  @click="handleEditTable"
                  >编辑</el-button
                >
              </div>
              <div class="text item">
                成立时间：{{ parseTime(detail.createTime, "{y}-{m}-{d}") }}
              </div>
              <div class="text item">联系地址：{{ detail.contactAddress }}</div>
              <div class="text item">联系人：{{ detail.linkMan }}</div>
            </el-card>
          </el-col>

          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <i class="el-icon-user-solid"></i>
                <span>{{ detail.linkMan }}</span>
              </div>
              <div class="title item" style="margin-bottom: 10px">拨通0次</div>
              <div class="phone text">
                <div class="left">
                  常用电话
                  <div class="value">{{ detail.mobile }}</div>
                </div>
                <div class="icon">
                  <i class="el-icon-phone" style="color: white"></i>
                </div>
              </div>
              <div class="phone text">
                <div class="left">
                  备用电话
                  <div class="value">{{ detail.alternatePhone }}</div>
                </div>
                <div class="icon" :class="{ disabled: !detail.alternatePhone }">
                  <i class="el-icon-phone" style="color: white"></i>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>

        <div class="title item" style="margin: 10px; text-align：right">
          <el-button-group>
            <el-button type="primary" icon="el-icon-arrow-left"
              >上一条</el-button
            >
            <el-button type="primary" icon="el-icon-arrow-left"
              >扔到公海</el-button
            >
            <el-button type="primary"
              >下一条<i class="el-icon-arrow-right el-icon--right"></i
            ></el-button>
          </el-button-group>
        </div>

        <record-form v-if="!!detail.id" :info="detail" />

        <custom-form ref="form" @ok="successChange" />
      </el-col>

      <!--此处应该是一个列表  -->
      <el-col :span="6">
        <el-table
          v-loading="loading"
          :data="tableList"
          @cell-click="showDetail"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            label="公司名称"
            align="left"
            prop="customerName"
            :show-overflow-tooltip="true"
          />
          <el-table-column label="联系电话" align="right" prop="mobile" />
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { listData } from "@/api/custom/index";
import recordForm from "./components/recordForm.vue";
import customForm from "./components/customForm.vue";

export default {
  name: "Gen",
  components: { recordForm, customForm },
  data() {
    return {
      // 数据
      detail: {},

      // 加载
      loading: false,
      // 表数据
      tableList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.tableList = JSON.parse(
      window.localStorage.getItem("selectCustomList")
    );
    this.detail = JSON.parse(window.localStorage.getItem("selectCustomDetail"));
  },

  methods: {
    // 表单高亮
    tableRowClassName({ row, rowIndex }) {
      if (row.id === this.detail.id) {
        return "success-row";
      }
      return "";
    },
    // 详情
    showDetail(row) {
      this.detail = row;
    },
    // 修改
    successChange(row) {
      window.localStorage.setItem(
        "selectCustomDetail",
        JSON.stringify({ ...this.detail, ...row })
      );
      this.detail = { ...this.detail, ...row };
    },
    /** 修改按钮操作 */
    handleEditTable() {
      this.$refs.form.show(this.detail);
    },
  },
};
</script>
<style lang="scss" scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.phone {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .left {
    color: gray;
    width: 100px;
    .value {
      color: #000;
      margin-top: 6px;
    }
  }
  .icon {
    margin-left: 14px;
    width: 40px;
    height: 40px;

    border-radius: 5px;
    background: rgb(0, 162, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    &.disabled {
      background: gray;
    }
  }
}
</style>
