import request from '@/utils/request'

//查询数据列表
export function listData(query) {
  return request({
    url: '/business/CallResourceData/list',
    method: 'get',
    params: query
  })
}

// 删除数据
export function delData(ids) {
  return request({
    url: '/business/CallResourceData/'+ids,
    method: 'delete'
  })
}


// 添加数据
export function addData(data) {
  return request({
    url: '/business/CallResourceData/save',
    method: 'post',
    data
  })
}


// 修改数据
export function updateData(data) {
  return request({
    url: '/business/CallResourceData/edit',
    method: 'post',
    data
  })
}


// 取回资源
export function backData(data) {
  return request({
    url: '/business/CallOriginalData/allocatingResource',
    method: 'post',
    data
  })
}

