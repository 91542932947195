var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.open,
        width: "1000px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.open = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司名称", prop: "customerName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入公司名称" },
                        model: {
                          value: _vm.form.customerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "customerName", $$v)
                          },
                          expression: "form.customerName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系地址", prop: "contactAddress" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入联系地址" },
                        model: {
                          value: _vm.form.contactAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contactAddress", $$v)
                          },
                          expression: "form.contactAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "date",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.form.creationDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "creationDate", $$v)
                          },
                          expression: "form.creationDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.open = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }