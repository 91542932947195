import request from '@/utils/request'

//查询分组
export function listGroup(query) {
  return request({
    url: '/business/CallGroup/getAll',
    method: 'get',
    params: query
  })
}

// 删除分组
export function delGroup(ids) {
  return request({
    url: '/business/CallGroup/'+ids,
    method: 'delete'
  })
}


// 添加分组
export function addGroup(data) {
  return request({
    url: '/business/CallGroup/save',
    method: 'post',
    data
  })
}


// 修改分组
export function updateGroup(data) {
  return request({
    url: '/business/CallGroup/edit',
    method: 'post',
    data
  })
}

