<template>
  <div style="margin-top: 20px;">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>销售信息</span>
      </div>
      <el-form ref="recordFrom" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="8">
            <!-- <el-form-item label="客户类型" prop="customerType">
              <el-select
                v-model="form.customerType"
                placeholder="请选择客户类型"
                clearable
                size="small"
              >
                <el-option
                  v-for="dict in customerOptions"
                  :key="dict.dictCode"
                  :label="dict.dictValue"
                  :value="dict.dictCode"
                />
              </el-select> </el-form-item 
          >-->
          </el-col>
          <el-col :span="8">
            <el-form-item label="销售进程" prop="salesProcess">
              <el-select
                v-model="form.salesProcess"
                placeholder="请选择销售进程"
                clearable
                size="small"
              >
                <el-option
                  v-for="dict in processOptions"
                  :key="dict.dictCode"
                  :label="dict.dictValue"
                  :value="dict.dictCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="行动标签">
            <el-radio-group v-model="form.remark" size="small">
              <el-radio-button
                v-for="dict in remarkOptions"
                :label="dict.dictValue"
                :key="dict.dictCode"
              ></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="联系小记" prop="contactNote">
            <el-input
              v-model="form.contactNote"
              type="textarea"
              placeholder="请输入与客户联系结果，最多可输入2000个汉字"
            />
          </el-form-item>
        </el-row>

        <el-row>
          <!-- <el-col :span="8">
            <el-form-item label="联系人" prop="contactName">
              <el-input v-model="form.contactName" placeholder="请输入联系人" />
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <!-- <el-form-item label="联系方式" prop="contactType">
              <el-select
                v-model="form.contactType"
                placeholder="请选择联系方式"
                clearable
                size="small"
              >
                <el-option
                  v-for="dict in typeOptions"
                  :key="dict.dictCode"
                  :label="dict.dictValue"
                  :value="dict.dictCode"
                />
              </el-select>
            </el-form-item> -->
          </el-col>
      
        </el-row>

         <el-row>
       
          <!-- <el-col :span="8">
            <el-form-item label="联系时间" prop="contactTime">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.contactTime"
                type="date"
                placeholder="选择时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col> -->
           <el-col :span="8">
            <el-form-item label="下次联系" prop="nextContact">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.nextContact"
                type="date"
                placeholder="选择时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitForm">淘到客户</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { addRecordTable } from "@/api/custom/origin";
import { updateData } from "@/api/custom/index";
export default {
  props: {
    info: {
      tyep: Object,
      default: {},
    },
  },
  data() {
    return {
      // 客户类型
      customerOptions: [],
      // 销售进程
      processOptions: [],
      // 联系方式
      typeOptions: [],
      // 行动标签
      remarkOptions: [],

      // 弹框
      rules: {
        customerType: [
          { required: true, message: "请选择客户类型", trigger: "change" },
        ],
        salesProcess: [
          { required: true, message: "请选择销售进程", trigger: "change" },
        ],
        contactName: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],

        contactNote: [
          { required: true, message: "联系小记不能为空", trigger: "blur" },
        ],
        contactType: [
          { required: true, message: "请选择联系方式", trigger: "change" },
        ],
        contactTime: [
          { required: true, message: "请选择联系时间", trigger: "change" },
        ],
        nextContact: [
          { required: true, message: "请选择下次联系时间", trigger: "change" },
        ],
      },
      form: {
        customerType: "",
        salesProcess: "",
        contactName: "",
        remark: "",
        contactTime: "",
        contactNote: "",
        contactType: "",
        nextContact: ''
      },
    };
  },
  created() {
    this.getDicts("sales_process").then((response) => {
      this.processOptions = response.data;
    });
    this.getDicts("customer_type").then((response) => {
      this.customerOptions = response.data;
    });
    this.getDicts("custom_contact_type").then((response) => {
      this.typeOptions = response.data;
    });
    this.getDicts("custom_contact_remark").then((response) => {
      this.remarkOptions = response.data;
    });
    this.form = {
        resourceDataId: this.info.id,
        customerType: Number(this.info.customerType),
        salesProcess: Number(this.info.salesProcess),
        contactName: this.info.linkMan,
      };
  },
  
  methods: {
   

    /** 提交按钮 */
    submitForm() {
      
      this.$refs["recordFrom"].validate((valid) => {
        if (valid) {
          addRecordTable({
            contactName: this.form.contactName,
            remark: this.form.remark,
            contactTime: this.form.contactTime,
            contactNote: this.form.contactNote,
            contactType: this.form.contactType,
            resourceDataId: this.form.resourceDataId,
          })
            .then(() => {
              return updateData({
                id: this.form.resourceDataId,
                customerType: this.form.customerType,
                salesProcess: this.form.salesProcess,
                nextContact: this.form.nextContact,
                lastContact: this.form.contactTime,
              });
            })
            .then((response) => {
              this.msgSuccess("设置成功");
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.content {
  padding: 10px 0;
  margin: 10px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  word-break: break-all;
}
.dialog-footer{
  display: flex;
  justify-content: flex-end;
}
</style>
