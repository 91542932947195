var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["cus-button", _vm.btnTypeStyle], on: { click: _vm.handleClick } },
    [
      !_vm.isLoading
        ? _c("span", [_vm._v(_vm._s(_vm.text))])
        : _c("div", { staticClass: "b-button-loading" }, [
            _c(
              "svg",
              {
                staticClass: "loading-svg b-button-size-huge b-button-primary",
                attrs: { viewBox: "0 0 50 50" },
              },
              [
                _c("circle", {
                  staticClass: "path",
                  staticStyle: { stroke: "rgba(255, 255, 255, 0.6)" },
                  attrs: { cx: "25", cy: "25", r: "20", fill: "none" },
                }),
              ]
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }