import request from "@/utils/request";
import { praseStrEmpty } from "@/utils/ruoyi";

// 查询用户列表
export function listUser(query) {
  return request({
    url: "/system/user/list",
    method: "get",
    params: query,
  });
}

// 查询用户详细
export function getUser(userId) {
  return request({
    url: "/system/user/" + praseStrEmpty(userId),
    method: "get",
  });
}

// 新增用户
export function addUser(data) {
  return request({
    url: "/system/user",
    method: "post",
    data: data,
  });
}

// 修改用户
export function updateUser(data) {
  return request({
    url: "/system/user",
    method: "put",
    data: data,
  });
}

// 删除用户
export function delUser(userId) {
  return request({
    url: "/system/user/" + userId,
    method: "delete",
  });
}

// 导出用户
export function exportUser(query) {
  return request({
    url: "/system/user/export",
    method: "get",
    params: query,
  });
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  const data = {
    userId,
    password,
  };
  return request({
    url: "/system/user/resetPwd",
    method: "put",
    data: data,
  });
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status,
  };
  return request({
    url: "/system/user/changeStatus",
    method: "put",
    data: data,
  });
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: "/system/user/profile",
    method: "get",
  });
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: "/system/user/profile",
    method: "put",
    data: data,
  });
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword,
  };
  return request({
    url: "/system/user/profile/updatePwd",
    method: "put",
    params: data,
  });
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: "/system/user/profile/avatar",
    method: "post",
    data: data,
  });
}

// 下载用户导入模板
export function importTemplate() {
  return request({
    url: "/system/user/importTemplate",
    method: "get",
  });
}

// 查询该公司下未设置负责人的员工
export function getIsNotLeader() {
  return request({
    url: "/system/user/getIsNotLeader",
    method: "get",
  });
}

// 发送短信验证码
export function smsCode(data) {
  return request({
    url: "/system/user/smsCode",
    method: "post",
    data: data,
  });
}

// 验证短信验证码
export function captchaCode(data) {
  return request({
    url: "/system/user/captchaCode",
    method: "post",
    data: data,
  });
}
// 个人中心设置，线路下拉查询
export function getDeptLine() {
  return request({
    url: "/business/isCurrentPhone/getDeptLine",
    // url:'business/isCurrentPhone/changeCallLine',
    method: "get",
  });
}

//绑定x号

export const insertXPhone = (data) => {
  return request({
    url: '/business/isCurrentPhone/insertXPhone',
    method: 'post',
    data
  })
}
