/*
 * @Author: your name
 * @Date: 2021-06-02 10:55:38
 * @LastEditTime: 2023-10-14 19:45:59
 * @LastEditors: dinghao dinghao361@gmail.com
 * @Description: In User Settings Edit
 * @FilePath: /call_manage_system_vue/src/main.js
 */
import Vue from "vue";
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);


Vue.use(Vuesax, {
    // options here

})
import Cookies from "js-cookie";

// import element from "element-ui";

// import "element-ui/lib/theme-chalk/index.css";

import "@/assets/styles/index.scss"; // global css
import "@/assets/styles/ruoyi.scss"; // ruoyi css
import App from "./App";
import store from "./store";
import router from "./router";
import permission from "./directive/permission";
import plugins from './plugins' // plugins
import "./assets/icons"; // icon
import "./permission"; // permission control
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import {
    parseTime,
    resetForm,
    addDateRange,
    selectDictLabel,
    selectDictLabels,
    download,
    handleTree,
} from "@/utils/ruoyi";
import Pagination from "@/components/Pagination";
// 自定义表格工具扩展
import RightToolbar from "@/components/RightToolbar";
import { initAegis } from '@/utils/aegis'
initAegis()

// 全局方法挂载
Vue.prototype.getDicts = getDicts;
Vue.prototype.getConfigKey = getConfigKey;
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectDictLabels = selectDictLabels;
Vue.prototype.download = download;
Vue.prototype.handleTree = handleTree;

Vue.prototype.msgSuccess = function (msg) {
    this.$message({ showClose: true, message: msg, type: "success" });
};

Vue.prototype.msgError = function (msg) {
    this.$message({ showClose: true, message: msg, type: "error" });
};

Vue.prototype.msgInfo = function (msg) {
    this.$message.info(msg);
};

// 全局组件挂载
Vue.component("Pagination", Pagination);
Vue.component("RightToolbar", RightToolbar);

Vue.use(permission);
Vue.use(plugins)

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

// Vue.use(element, {
//   size: Cookies.get("size") || "medium", // set element-ui default size
// });

Vue.config.productionTip = false;

new Vue({
    el: "#app",
    router,

    store,
    render: (h) => h(App),
});

