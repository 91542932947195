<template>
  <el-dialog :title="title" :visible.sync="open" width="1000px" append-to-body>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="公司名称" prop="customerName">
            <el-input
              v-model="form.customerName"
              placeholder="请输入公司名称"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系地址" prop="contactAddress">
            <el-input
              v-model="form.contactAddress"
              placeholder="请输入联系地址"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建时间">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.creationDate"
              type="date"
              placeholder="选择时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="open = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { updateData } from "@/api/custom/index";

export default {
  data() {
    return {
      // 弹框
      rules: {
        customerName: [
          { required: true, message: "公司名称不能为空", trigger: "blur" },
        ],
        contactAddress: [
          { required: true, message: "联系地址不能为空", trigger: "blur" },
        ],

        creationDate: [
          { required: true, message: "请选择创建时间", trigger: "change" },
        ],
      },
      form: {
        customerName: "",
        contactAddress: "",

        creationDate: "",
      },
      grouping: [],
      open: false,
      title: "",
      // 分组
      idMap: {},
      options: [],
      allId: "",
    };
  },

  methods: {
    // 显示弹框
    show(row) {
      this.open = true;
    
      this.title = "修改客户信息";
      this.form = {
        id: row.id,
        customerName: row.customerName,
        contactAddress: row.contactAddress,
        creationDate: row.creationDate,
      };
    },

    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          updateData(this.form).then((response) => {
            this.msgSuccess("修改成功");
            this.open = false;
            this.$emit("ok", this.form);
          });
        }
      });
    },
  },
};
</script>
