var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "basic" } },
            [
              _c("basic-info-form", {
                ref: "basicInfo",
                attrs: { info: _vm.info },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "字段信息", name: "cloum" } },
            [
              _c(
                "el-table",
                {
                  ref: "dragTable",
                  attrs: {
                    data: _vm.cloumns,
                    "row-key": "columnId",
                    "max-height": _vm.tableHeight,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      "min-width": "5%",
                      "class-name": "allowDrag",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "字段列名",
                      prop: "columnName",
                      "min-width": "10%",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "字段描述", "min-width": "10%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              model: {
                                value: scope.row.columnComment,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "columnComment", $$v)
                                },
                                expression: "scope.row.columnComment",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "物理类型",
                      prop: "columnType",
                      "min-width": "10%",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Java类型", "min-width": "11%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: scope.row.javaType,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "javaType", $$v)
                                  },
                                  expression: "scope.row.javaType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "Long", value: "Long" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "String", value: "String" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "Integer", value: "Integer" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "Double", value: "Double" },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: "BigDecimal",
                                    value: "BigDecimal",
                                  },
                                }),
                                _c("el-option", {
                                  attrs: { label: "Date", value: "Date" },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "java属性", "min-width": "10%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              model: {
                                value: scope.row.javaField,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "javaField", $$v)
                                },
                                expression: "scope.row.javaField",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "插入", "min-width": "5%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": "1" },
                              model: {
                                value: scope.row.isInsert,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isInsert", $$v)
                                },
                                expression: "scope.row.isInsert",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "编辑", "min-width": "5%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": "1" },
                              model: {
                                value: scope.row.isEdit,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isEdit", $$v)
                                },
                                expression: "scope.row.isEdit",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "列表", "min-width": "5%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": "1" },
                              model: {
                                value: scope.row.isList,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isList", $$v)
                                },
                                expression: "scope.row.isList",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "查询", "min-width": "5%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": "1" },
                              model: {
                                value: scope.row.isQuery,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isQuery", $$v)
                                },
                                expression: "scope.row.isQuery",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "查询方式", "min-width": "10%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: scope.row.queryType,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "queryType", $$v)
                                  },
                                  expression: "scope.row.queryType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "=", value: "EQ" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "!=", value: "NE" },
                                }),
                                _c("el-option", {
                                  attrs: { label: ">", value: "GT" },
                                }),
                                _c("el-option", {
                                  attrs: { label: ">=", value: "GTE" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "<", value: "LT" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "<=", value: "LTE" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "LIKE", value: "LIKE" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "BETWEEN", value: "BETWEEN" },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "必填", "min-width": "5%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": "1" },
                              model: {
                                value: scope.row.isRequired,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isRequired", $$v)
                                },
                                expression: "scope.row.isRequired",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "显示类型", "min-width": "12%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: scope.row.htmlType,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "htmlType", $$v)
                                  },
                                  expression: "scope.row.htmlType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "文本框", value: "input" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "文本域", value: "textarea" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "下拉框", value: "select" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "单选框", value: "radio" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "复选框", value: "checkbox" },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: "日期控件",
                                    value: "datetime",
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: "图片上传",
                                    value: "imageUpload",
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: "文件上传",
                                    value: "fileUpload",
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: "富文本控件",
                                    value: "editor",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "字典类型", "min-width": "12%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: scope.row.dictType,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "dictType", $$v)
                                  },
                                  expression: "scope.row.dictType",
                                },
                              },
                              _vm._l(_vm.dictOptions, function (dict) {
                                return _c(
                                  "el-option",
                                  {
                                    key: dict.dictType,
                                    attrs: {
                                      label: dict.dictName,
                                      value: dict.dictType,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { float: "left" } },
                                      [_vm._v(_vm._s(dict.dictName))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          color: "#8492a6",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(dict.dictType))]
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "生成信息", name: "genInfo" } },
            [
              _c("gen-info-form", {
                ref: "genInfo",
                attrs: { info: _vm.info, tables: _vm.tables, menus: _vm.menus },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            {
              staticStyle: {
                "text-align": "center",
                "margin-left": "-100px",
                "margin-top": "10px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }