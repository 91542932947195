import Aegis from 'aegis-web-sdk';
import Vue from 'vue'
function changeURLArg(url, arg, arg_val) {
    var pattern = arg + ' = ([^&]*)';
    var replaceText = arg + '=' + arg_val;
    if (url.match(pattern)) {
        var tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
    }
    return url;
}
let aegis = null;
export function initAegis() {
    let aegisUin = window.localStorage.getItem('aegisUin')
    let aegisAid = window.localStorage.getItem('aegisAid')
    if (!aegisUin) {
        return
    }
    if (aegis) {
        aegis.destroy()
        aegis = null
        window.aegis = null
    }
    let config = {
        id: 'bR6kGf6mOYlEEG95eL', // 上报 id
        aid: '',
        reportApiSpeed: true, // 接口测速
        reportAssetSpeed: true, // 静态资源测速
        spa: true, // spa 应用页面跳转的时候开启 pv 计算
        hostUrl: 'https://rumt-zh.com',
        repeat: 300,
        onError: true,
        onBeforeRequest(log) {
            if (log.type === 'performance') {
                // Page speed test. Here, you can modify the content of `log`; for example, you can modify the `platform` for page speed test
                log.url = changeURLArg(log.url, 'platform')
            }
            return log
        },
        api: {
            apiDetail: true,
            retCodeHandler: (data, url, xhr) => {
                // data 是string类型，如果需要对象需要手动parse下
                // url 为请求url
                // xhr 响应,可以通过xhr.response拿到完整的后台响应
                try {
                    data = JSON.parse(data)
                } catch (e) { }
                return {
                    // isErr 如果是 true 的话，会上报一条 retcode 异常的日志。
                    isErr: data.body.code !== 200 || data.body.retCode !== 0,
                    code: data.body.code
                }
            },
            reportRequest: true,
        },
    }
    if (aegisUin) {
        config['uin'] = aegisUin
        config['aid'] = aegisAid
    }
    aegis = new Aegis(config);
    Vue.config.errorHandler = function (err, vm, info) {
        console.log(`Error: ${err.toString()}\nStack: ${err.stack}\nInfo: ${info}`);
        aegis.error(`外呼系统-pc的js报错: Error: ${err.toString()}\nStack: ${err.stack}\nInfo: ${info}`);
    };
    window.aegis = aegis
}