<!--
 * @Author: dinghao dinghao361@gmail.com
 * @Date: 2023-07-28 23:39:42
 * @LastEditors: dinghao dinghao361@gmail.com
 * @LastEditTime: 2023-07-29 00:20:31
 * @FilePath: /call_manage_system_vue/src/views/system/user/profile/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="12" :xs="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="line"></span>
            <span>个人信息</span>
          </div>
          <div>
            <div class="text-center">
              <userAvatar :user="user" />
            </div>
            <ul class="list-group list-group-striped">
              <li class="list-group-item">
                <!-- <svg-icon icon-class="user" /> -->
                账号
                <div class="pull-right">{{ user.userName }}</div>
              </li>
              <!-- <li class="list-group-item">
                <svg-icon icon-class="phone" />手机号码
                <div class="pull-right">{{ user.phonenumber }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="email" />用户邮箱
                <div class="pull-right">{{ user.email }}</div>
              </li> -->
              <li class="list-group-item">
                <!-- <svg-icon icon-class="tree" /> -->
                公司-部门
                <div class="pull-right" v-if="user.dept">
                  {{ user.dept.deptName }} / {{ postGroup || "--"}}
                </div>
              </li>
              <li class="list-group-item">
                <!-- <svg-icon icon-class="peoples" /> -->
                角色
                <div class="pull-right">{{ roleGroup }}</div>
              </li>
              <li class="list-group-item">
                <!-- <svg-icon icon-class="date" /> -->
                创建日期
                <div class="pull-right">{{ user.createTime }}</div>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-card  class="box-card">
          <div slot="header" class="clearfix">
            <span class="line"></span>  
            <span>基本资料</span>
          </div>
          <el-tabs v-model="activeTab">
            <!-- <el-tab-pane label="基本资料" name="userinfo">
              <userInfo :user="user" />
            </el-tab-pane> -->
            <el-tab-pane label="修改登录密码" name="resetPwd">
              <resetPwd :user="user" />
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userAvatar from "./userAvatar";
import userInfo from "./userInfo";
import resetPwd from "./resetPwd";
import { getUserProfile } from "@/api/system/user";

export default {
  name: "Profile",
  components: { userAvatar, userInfo, resetPwd },
  data() {
    return {
      user: {},
      roleGroup: {},
      postGroup: {},
      activeTab: "resetPwd",
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      getUserProfile().then((response) => {
        this.user = response.data;
        this.roleGroup = response.roleGroup;
        this.postGroup = response.postGroup;
      });
    },
  },
};
</script>
<style scoped>
.el-card{
  border-radius:20px;
}

</style>
