<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { getToken, removeToken } from "@/utils/auth";
export default {
    name: 'App',
    beforeUpdate() {
        console.log(getToken())
        if (getToken()) {
            this.$store.commit("SET_TOKEN", getToken());
        } else {
            this.$store.commit("SET_TOKEN", "");
            this.$store.commit("SET_ROLES", []);
            this.$store.commit("SET_PERMISSIONS", []);
            removeToken();
        }
        this.$store.dispatch('webSocketConnect');//初始化ws
    },
    beforeDestroy() {
        this.$store.dispatch('webSocketDisconnect');//关闭websocket
    },

}
</script>
