<!--
 * @Author: your name
 * @Date: 2021-06-16 22:35:19
 * @LastEditTime: 2023-08-20 15:38:08
 * @LastEditors: dinghao dinghao361@gmail.com
 * @Description: 注册页面
 * @FilePath: /call_manage_system_vue/src/views/login.vue
-->
<template>
  <div class="login">
    <el-form
      ref="elForm"
      :model="formData"
      :rules="rules"
      size="medium"
      label-width="100px"
      class="login-form"
    >
      <h3 class="title">你好</h3>
      <p style="text-align: center">
        <span style="color: #1890ff">{{ companyName }}-{{ inviterName }}</span>
        邀请你进行注册
      </p>
      <el-row gutter="15">
        <el-form-item label="姓名" prop="field102">
          <el-input
            v-model="formData.field102"
            placeholder="请输入姓名"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-form-item label="手机号" prop="mobile">
        <el-input
          v-model="formData.mobile"
          placeholder="请输入手机号"
          :maxlength="11"
          show-word-limit
          clearable
          prefix-icon="el-icon-mobile"
          :style="{ width: '100%' }"
        ></el-input>
      </el-form-item>
      <el-form-item label="短信验证码" prop="field105">
        <el-input
          v-model="formData.field105"
          placeholder="请输入短信验证码"
          clearable
          :style="{ width: '100%' }"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="密码" prop="field103">
        <el-input
          v-model="formData.field103"
          placeholder="请输入密码"
          clearable
          :style="{ width: '100%' }"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="field104">
        <el-input
          v-model="formData.field104"
          placeholder="再次确认密码"
          clearable
          show-password
          :style="{ width: '100%' }"
        ></el-input>
      </el-form-item>
      <el-form-item size="large">
        <el-button type="primary" @click="submitForm">提交</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>

    <!--  底部  -->
    <div class="el-login-footer">
      <span
        >Copyright ©2013-2023浙江网看创享科技有限公司 企鱼 | 浙ICP备18020380号-4</span
      >
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import Verify from "@/components/Verifition/Verify";

export default {
  components: { Verify },
  name: "login",
  data() {
    return {
      formData: {
        mobile: "",
        field102: undefined,
        field103: undefined,
        field104: undefined,
        field105: undefined,
        field106: undefined,
      },
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1(3|4|5|7|8|9)\d{9}$/,
            message: "手机号格式错误",
            trigger: "blur",
          },
        ],
        field102: [
          {
            required: true,
            message: "请输入单行文本",
            trigger: "blur",
          },
        ],
        field103: [
          {
            required: true,
            message: "请输入单行文本",
            trigger: "blur",
          },
        ],
        field104: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        field105: [
          {
            required: true,
            message: "请输入单行文本",
            trigger: "blur",
          },
        ],
        field106: [
          {
            required: true,
            message: "请输入单行文本",
            trigger: "blur",
          },
        ],
      },
      companyName: "企鱼",
      inviterName: "丁浩",
      loginForm: {
        username: "admin",
        password: "admin123",
        rememberMe: false,
        code: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" },
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
        ],
      },
      loading: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.getCookie();
  },
  methods: {
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;
        // TODO 提交表单
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password:
          password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },

    // 测试修改 注释代码
    capctchaCheckSuccess(params) {
      this.loginForm.code = params.captchaVerification;
      this.loading = true;
      if (this.loginForm.rememberMe) {
        Cookies.set("username", this.loginForm.username, { expires: 30 });
        Cookies.set("password", encrypt(this.loginForm.password), {
          expires: 30,
        });
        Cookies.set("rememberMe", this.loginForm.rememberMe, { expires: 30 });
      } else {
        Cookies.remove("username");
        Cookies.remove("password");
        Cookies.remove("rememberMe");
      }
      this.$store
        .dispatch("login", this.loginForm)
        .then(() => {
          this.$router.push({ path: this.redirect || "/" }).catch(() => {});
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handlelogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$refs.verify.show();
        }
      });
    },
  },
};
</script>

<style  scoped rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("../assets/images/login-background.jpg");
  background-size: cover;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
    span{
      text-align: center;
    font-size: 14px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 88px;
    margin-top: 68px;
    padding: 12px 118px;
    }
}
</style>


