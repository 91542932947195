//参数为时间差秒数，返回这两个时间差并格式化
function computeTimeDiff(diff) {
    diff = Math.round(diff / 1000);
    let hour = Math.floor(diff / 3600).toString().padStart(2, '0');
    let min = Math.floor((diff - hour * 3600) / 60).toString().padStart(2, '0');
    let sec = (diff % 60).toString().padStart(2, '0');
    return hour + ':' + min + ':' + sec;
}
//播放挂机铃声
function playHangupMedia() {
    var hangupAudio = document.getElementById("hangupMediaAudioId")
    if (!hangupAudio) {
        hangupAudio = document.createElement('audio');
        hangupAudio.id = 'hangupMediaAudioId';
        hangupAudio.hidden = true;
        hangupAudio.src = 'https://img.iqiyu.net/sip_source/hangup.wav'
        document.body.appendChild(hangupAudio);
    }
    hangupAudio.play();
}
//播放来电振铃
function playRingMedia() {
    stopPlayRingMedia();
    var ringAudio = document.getElementById("ringMediaAudioId")
    if (!ringAudio) {
        ringAudio = document.createElement('audio');
        ringAudio.id = 'ringMediaAudioId';
        ringAudio.hidden = true;
        ringAudio.src = 'https://img.iqiyu.net/sip_source/ring.wav';
        ringAudio.loop = 'loop';
        document.body.appendChild(ringAudio);
    }
    ringAudio.play();
}
//停止播放来电振铃
function stopPlayRingMedia() {
    var ringAudio = document.getElementById("ringMediaAudioId");
    if (ringAudio) {
        document.body.removeChild(ringAudio);
    }
}
let timer, timerString;
//重置时间
let restoreTime = (origin) => {
    clearInterval(timer);
    timerString = '00:00:00';
    timer = setInterval(() => {
        timerString = computeTimeDiff(new Date().getTime() - origin);
    }, 1000);
}

//状态变更回调
let stateEventListener = (event, data) => {
    //debug使用
    console.log('当前event为: ' + event + ', 当前data为: ' + JSON.stringify(data))
    let origin = new Date().getTime();
    switch (event) {
        case "CONNECTED":
            // this.agentNo = data.localAgent
            restoreTime(origin);
            break;
        case "DISCONNECTED":
            restoreTime(origin);
            break;

        case "UNREGISTERED":
            restoreTime(origin);
            break;

        case "OUTGOING_CALL":
            // this.customerNo = data.otherLegNumber;
            restoreTime(origin);
            break;

        case "INCOMING_CALL":
            // this.customerNo = data.otherLegNumber;
            //播放来电振铃音
            playRingMedia();
            restoreTime(origin);
            break;

        case "IN_CALL":
            stopPlayRingMedia();
            restoreTime(origin);
            break;

        case "CALL_END":
            stopPlayRingMedia();
            //挂机铃声
            playHangupMedia()
            restoreTime(origin);
            break;

        default:
    }
}


let error = {
    10001: '请求未携带X - AccessToken',
    11101: '当前ecp号码未开⼾',
    11102: '当前ecp号码处于停机状态',
    11103: '当前ECP号码当天呼叫时⻓已经达到上限',
    11104: '当前ecp号码不属于请求⽅',
    11105: 'ECP号码和主叫号码不⼀致',
    11106: '你的ECP号码尚未激活，请激活后再试',
    11107: '当前ECP号码半⼩时拨打次数达到上限',
    11108: '当前ECP号码每⽇拨打次数达到上限',
    11109: 'ECP号码⿊名单',
    11110: '当前时间段⽆法调⽤',
    11111: '通话时⻓剩余资源不⾜，⽆法发起呼叫',
    11112: '被叫⿊名单',
    11113: '被叫号码当⽇被拨打次数达到上限',
    11114: '被叫号码当⽇全平台被拨打次数达到上限',
    11115: '当前appId不属于调⽤的账号',
    11116: '当前ip不在配置的应⽤⽩名单内',
    11117: 'access token失效',
    11118: 'access token关联的app应⽤不存在',
    11130: '调⽤呼叫中⼼⻛控失败：如短时内被叫多次，hcode误等'
}
let config = {
    host: '1018sip.tongxinmao.net',
    port: '7443',
    proto: true,//是否是wss true ws false
    extNo: '',
    extPwd: '123456',
    autoRegister: true,
    debug: true,
    stunServer: 'stun.freeswitch.org',   //可自行修改使用stun服务器地址
    stateEventListener: stateEventListener
}
let loginSip = () => {
    // 初始化
    Ctibar.initSDK(config)
    // 注册
    Ctibar.register()
}
let logout = () => {
    Ctibar.unregister()
}

export default {
    loginSip: loginSip,
    logout: logout,
    timerString: timerString,
    makeCall(phone) {
        Ctibar.makecall(phone);
    },
    hold() {
        Ctibar.hold();
    },
    unhold() {
        Ctibar.unhold();
    },
    answer() {
        Ctibar.answer();
    },
    hangup() {
        Ctibar.hangup();
    },
}
