<template>
  <el-row :gutter="20" class="panel-group">
    <el-col :lg="6" class="card-panel-col flex-start-center">
      <div style="width: 100%; font-size: 16px;">
        <div style="line-height: 60px;">
          <i class="el-icon-notebook-1"></i>
          资料统计
        </div>
        <!-- <div class="flex-start-center"> -->
          

          <!-- <div class="card-panel" @click="handleSetLineChartData('messages')">
            <div class="card-panel-description">
              <div class="card-panel-text">
                公司数量
              </div>
              <count-to :start-val="0" :end-val="Number(statisticsInfo.deptCount)" :duration="3000" class="card-panel-num" />
              <div class="">
                当前公司数量
              </div>
            </div>
            <div class="card-panel-icon icon-message">实时</div>
          </div> -->
        <!-- </div> -->
        <div class="card-panel card-panel-only" @click="handleSetLineChartData('newVisitis')">
            <div class="card-panel-description">
              <div class="card-panel-text">
                坐席数量
              </div>
              <count-to :start-val="0" :end-val="Number(statisticsInfo.seatCount)" :duration="2600" class="card-panel-num" />
              <div class="">
                当前坐席总数
              </div>
            </div>
            <div class="card-panel-icon icon-people">实时</div>
          </div>
      </div>
      
    </el-col>
    <el-col :lg="9" class="card-panel-col flex-start-center">
      <div style="width: 100%; font-size: 16px;">
        <div style="line-height: 60px;">
          <i class="el-icon-date"></i>
          话务统计
        </div>
        <div class="flex-start-center">
          <div class="card-panel" @click="handleSetLineChartData('purchases')">
            <div class="card-panel-description">
              <div class="card-panel-text">
                呼叫总数
              </div>
              <count-to :start-val="0" :end-val="Number(statisticsInfo.callCount)" :duration="3200" class="card-panel-num" />
              <div class="">
                当前呼叫总数
              </div>
            </div>
            <div class="card-panel-icon icon-money">实时</div>
          </div>
          
          <div class="card-panel" @click="handleSetLineChartData('shoppings')">
            <div class="card-panel-description">
              <div class="card-panel-text">
                通话成功率
              </div>
              <count-to :start-val="0" :end-val="Number(statisticsInfo.callRate)" :duration="3600" class="card-panel-num" />
              <div class="">
                今日通话成功率
              </div>
            </div>
            <div class="card-panel-icon icon-shopping">实时</div>
          </div>
        </div>
      </div>

    </el-col>
    <el-col :lg="6" class="card-panel-col flex-start-center">
      <div style="width: 100%; font-size: 16px;">
        <div style="line-height: 60px;">
          <i class="el-icon-office-building"></i>
          公司信息
        </div>
        <div class="card-panel card-panel-only" @click="handleSetLineChartData('newVisitis')">
          <div class="card-panel-description">
            <!-- <div class="card-panel-text">
              公司名称:
            </div> -->
            <div class="mt6">
              当月话费总数:{{ Number(statisticsInfo.sumAmount) }}
            </div>
            <!-- <div class="mt6">
              AccountKey:
            </div>
            <div class="mt6">
              AccountSecret:
            </div> -->
          </div>
        </div>
      </div>
    </el-col>
    <!-- <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('shoppings')">
        <div class="card-panel-description">
          <div class="card-panel-text">
            通话成功率
          </div>
          <count-to :start-val="0" :end-val="13600" :duration="3600" class="card-panel-num" />
          <div class="">
            今日通话成功率
          </div>
        </div>
        <div class="card-panel-icon icon-shopping">实时</div>
      </div>
    </el-col> -->
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to'
import { getStatisticsInfo } from "@/api/business/company";

export default {
  components: {
    CountTo
  },
  data() {
    return {
      statisticsInfo: {}
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type)
    },
    async getStatisticsInfo() {
      const res = await getStatisticsInfo();
      if (res.code !== 200) return this.$message.error(res.msg);
      this.statisticsInfo = res.data
    }
  },
  mounted() {
    this.getStatisticsInfo();
  }
}
</script>

<style lang="scss" scoped>
.mt6 {
  margin-top: 4px;
}
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }
  
  .card-panel-only {
    width: 100% !important;
  }
  .card-panel {
    height: 108px;
    width: 50%;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      background-color: #40c9c6;
    }

    .icon-message {
      background-color: #36a3f7;
    }

    .icon-money {
      background-color: #f4516c;
    }

    .icon-shopping {
      background-color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      color: #fff;
      width: 38px;
      height: 20px;
      float: right;
      font-weight: 700;
      // font-size: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card-panel-description {
      float: left;
      font-weight: bold;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
