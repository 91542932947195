var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "panel-group", attrs: { gutter: 20 } },
    [
      _c(
        "el-col",
        { staticClass: "card-panel-col flex-start-center", attrs: { lg: 6 } },
        [
          _c("div", { staticStyle: { width: "100%", "font-size": "16px" } }, [
            _c("div", { staticStyle: { "line-height": "60px" } }, [
              _c("i", { staticClass: "el-icon-notebook-1" }),
              _vm._v(" 资料统计 "),
            ]),
            _c(
              "div",
              {
                staticClass: "card-panel card-panel-only",
                on: {
                  click: function ($event) {
                    return _vm.handleSetLineChartData("newVisitis")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v(" 坐席数量 "),
                    ]),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": Number(_vm.statisticsInfo.seatCount),
                        duration: 2600,
                      },
                    }),
                    _c("div", {}, [_vm._v(" 当前坐席总数 ")]),
                  ],
                  1
                ),
                _c("div", { staticClass: "card-panel-icon icon-people" }, [
                  _vm._v("实时"),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col flex-start-center", attrs: { lg: 9 } },
        [
          _c("div", { staticStyle: { width: "100%", "font-size": "16px" } }, [
            _c("div", { staticStyle: { "line-height": "60px" } }, [
              _c("i", { staticClass: "el-icon-date" }),
              _vm._v(" 话务统计 "),
            ]),
            _c("div", { staticClass: "flex-start-center" }, [
              _c(
                "div",
                {
                  staticClass: "card-panel",
                  on: {
                    click: function ($event) {
                      return _vm.handleSetLineChartData("purchases")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v(" 呼叫总数 "),
                      ]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": Number(_vm.statisticsInfo.callCount),
                          duration: 3200,
                        },
                      }),
                      _c("div", {}, [_vm._v(" 当前呼叫总数 ")]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "card-panel-icon icon-money" }, [
                    _vm._v("实时"),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "card-panel",
                  on: {
                    click: function ($event) {
                      return _vm.handleSetLineChartData("shoppings")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v(" 通话成功率 "),
                      ]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": Number(_vm.statisticsInfo.callRate),
                          duration: 3600,
                        },
                      }),
                      _c("div", {}, [_vm._v(" 今日通话成功率 ")]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "card-panel-icon icon-shopping" }, [
                    _vm._v("实时"),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col flex-start-center", attrs: { lg: 6 } },
        [
          _c("div", { staticStyle: { width: "100%", "font-size": "16px" } }, [
            _c("div", { staticStyle: { "line-height": "60px" } }, [
              _c("i", { staticClass: "el-icon-office-building" }),
              _vm._v(" 公司信息 "),
            ]),
            _c(
              "div",
              {
                staticClass: "card-panel card-panel-only",
                on: {
                  click: function ($event) {
                    return _vm.handleSetLineChartData("newVisitis")
                  },
                },
              },
              [
                _c("div", { staticClass: "card-panel-description" }, [
                  _c("div", { staticClass: "mt6" }, [
                    _vm._v(
                      " 当月话费总数:" +
                        _vm._s(Number(_vm.statisticsInfo.sumAmount)) +
                        " "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }