<template>
    <div class="app-container home">
        <div class="card-box wrap">
            <!--  v-if="roles[0] === 'admin'" -->
            <vs-card class="item">
                <template #title>
                    <h3>用户手册</h3>
                </template>
                <template #img>
                    <img src="../assets/images/guide.jpg" alt="">
                </template>
                <template #text>
                    <p>
                        无论何时，您遇到的绝大部分问题都可以在这里找到答案。如仍未解决，请联系客服人员, 客服微信号: mando717
                    </p>
                    <a href="https://www.yuque.com/iqiyu/saas" target="_blank">点击查看企鱼用户手册</a>
                </template>
                <template #interactions>
                </template>
            </vs-card>


            <vs-card class="item">
                <template #title>
                    <h3>使用问题反馈</h3>
                </template>
                <template #img>
                    <img src="../assets/logo/kefu.png" alt="">
                </template>
                <template #text>
                    <p>
                        您的意见和建议对企鱼来讲都非常重要！如发现问题请告知我们，让我们一起变得更好。
                    </p>
                    <a href="https://docs.qq.com/sheet/DZHdaRU1IVVB3UmNX?tab=BB08J2" target="_blank">点击查看表格，填写问题反馈</a>
                </template>
                <template #interactions>
                </template>
            </vs-card>

            <vs-card class="item">
                <template #title>
                    <h3>"企鱼获客" 新功能免费用</h3>
                </template>
                <template #img>
                    <img src="../assets/images/data.png" alt="">
                </template>
                <template #text>
                    <p></p>
                    <p>
                        即将推出精准拓客功能“企鱼获客”。通过大模型技术获取互联网上全维度高精准的全国企业信息，经数据清洗提升资源精确度，加速成交。可在左侧”企鱼获客“菜单中免费领取线索（每天可领取300条/公司）。
                    </p>
                </template>
                <template #interactions>
                </template>
            </vs-card>

            <vs-card class="item">
                <template #title>
                    <h3>引荐客户，获得奖励</h3>
                </template>
                <template #img>
                    <img src="../assets/logo/qrcode.jpeg" alt="">
                </template>
                <template #text>
                    <p></p>
                    <p>
                        如果您认为企鱼软件好用（包含 外呼系统，CRM客户管理系统，大数据拓客系统，百度口袋资料库），请将企鱼推荐给您身边的公司朋友，在他们试用且成功购买后，您将获得返利优惠奖励。
                    </p>
                </template>
                <template #interactions>
                </template>
            </vs-card>
        </div>
        <!-- <div v-else> -->

        <!-- <panel-group />
      <div style="line-height: 60px; font-size: 16px;">
        <i class="el-icon-pie-chart"></i>
        近一个月统计表
      </div>
      <el-row style="background:#fff;padding:10px 10px 0;margin-bottom:32px;">
        <line-chart :chart-data="lineChartData" :time-line="timeLine" />
      </el-row> -->

        <!-- </div> -->
    </div>
</template>

<script>
import PanelGroup from './dashboard/PanelGroup'
import LineChart from './dashboard/LineChart'
import { getBrokenLine } from "@/api/business/company";
import store from '@/store'
// const roles = store.getters && store.getters.roles
// import { jssipInit } from '@/utils/qrcall'
import { getInfo } from "@/api/login";
import { initAegis } from '@/utils/aegis'
export default {
    name: "index",
    components: {
        PanelGroup,
        LineChart,
    },
    data() {
        return {
            // roles,
            texts: [],
            // 版本号
            version: "3.5.0",
            timeLine: [],
            lineChartData: {
                // expectedData: [1000, 1200, 1610, 1340, 1050, 1600, 1650]
                actualData: [1200, 820, 910, 1540, 1620, 1400, 1450]
            },
        };
    },
    methods: {
        goTarget(href) {
            window.open(href, "_blank");
        },
        handleClick() { },
        initData() {
            // 【腾讯文档】问题反馈表格
            // https://docs.qq.com/sheet/DZHdaRU1IVVB3UmNX?tab=BB08J2
            let textArr = [
                {
                    title: "问题反馈",
                    desc: "您的意见是我们最想听到的，有什么问题请告诉我们 让我们一起变得更好",
                    img: "https://vuesax.com/foto6.png",
                },
                {
                    title: "用户手册",
                    desc: "无论何时，您遇到的问题大部分都可以在这里解决，请查看用户手册，如果仍未解决，请联系我们客服人员您的意见是我们最想听到的，有什么问题请告诉我们 让我们一起变得更好",
                    img: "https://vuesax.com/foto6.png",
                },
                {
                    title: "新增企鱼获客功能",
                    desc: "每日为用户提供一定数量的企业名单资源",
                    img: "https://vuesax.com/foto6.png",
                },
                {
                    title: "问题反馈",
                    desc: "您的意见是我们最想听到的，有什么问题请告诉我们 让我们一起变得更好",
                    img: "https://vuesax.com/foto6.png",
                },
            ];
            this.texts = textArr;
        },
        async getLine() {
            const res = await getBrokenLine();
            // console.log(res, "折线");
            if (res.code !== 200) return this.$message.error(res.msg);
            this.lineChartData.actualData = res.data.y
            this.timeLine = res.data.x
        },
    },
    created() {
        this.initData();
        // let qrcall = jssipInit(false)
        // qrcall.reg({
        //     exNumber: '057181933627',
        //     port: '7443',
        //     regFs: '1018sip.tongxinmao.net',
        //     password: '123456'
        // })
        // setTimeout(() => {
        //     qrcall.testCall(18002535451)
        // }, 12000);
        // console.log(qrcall)
        getInfo()
            .then((res) => {
                const user = res.user;
                window.localStorage.setItem('aegisUin', user.userName)
                window.localStorage.setItem('aegisAid', user.companyName)
                initAegis()
            })
            .catch((error) => { });
    },
    mounted() {
        // this.getLine();

    }
};
</script>

<style scoped lang="scss">
a {
    display: block;
    margin-top: 5px;
    color: black;
    text-decoration-line: underline;

}

a:hover {
    color: #1890FF;
    /* 设置悬停时的颜色 */
}

.card-box {
    display: flex;
    justify-content: space-evenly;
}

.wrap {
    flex-wrap: wrap;
}

.item {
    margin: 8px 0 0 8px;
    max-width: 260px;
}

.home {
    blockquote {
        padding: 10px 20px;
        margin: 0 0 20px;
        font-size: 17.5px;
        border-left: 5px solid #eee;
    }

    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #eee;
    }

    .col-item {
        margin-bottom: 20px;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    font-family: "open sans",
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;
    font-size: 13px;
    color: #676a6c;
    overflow-x: hidden;

    ul {
        list-style-type: none;
    }

    h4 {
        margin-top: 0px;
    }

    h2 {
        margin-top: 10px;
        font-size: 26px;
        font-weight: 100;
    }

    p {
        margin-top: 10px;

        b {
            font-weight: 700;
        }
    }

    .update-log {
        ol {
            display: block;
            list-style-type: decimal;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0;
            margin-inline-end: 0;
            padding-inline-start: 40px;
        }
    }
}
</style>
