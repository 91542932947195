var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          staticStyle: { height: "224px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.detail.customerName)),
                              ]),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    float: "right",
                                    padding: "3px 0",
                                  },
                                  attrs: { icon: "el-icon-edit", type: "text" },
                                  on: { click: _vm.handleEditTable },
                                },
                                [_vm._v("编辑")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "text item" }, [
                            _vm._v(
                              " 成立时间：" +
                                _vm._s(
                                  _vm.parseTime(
                                    _vm.detail.createTime,
                                    "{y}-{m}-{d}"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "text item" }, [
                            _vm._v(
                              "联系地址：" + _vm._s(_vm.detail.contactAddress)
                            ),
                          ]),
                          _c("div", { staticClass: "text item" }, [
                            _vm._v("联系人：" + _vm._s(_vm.detail.linkMan)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-card", { staticClass: "box-card" }, [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c("i", { staticClass: "el-icon-user-solid" }),
                            _c("span", [_vm._v(_vm._s(_vm.detail.linkMan))]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "title item",
                            staticStyle: { "margin-bottom": "10px" },
                          },
                          [_vm._v("拨通0次")]
                        ),
                        _c("div", { staticClass: "phone text" }, [
                          _c("div", { staticClass: "left" }, [
                            _vm._v(" 常用电话 "),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.detail.mobile)),
                            ]),
                          ]),
                          _c("div", { staticClass: "icon" }, [
                            _c("i", {
                              staticClass: "el-icon-phone",
                              staticStyle: { color: "white" },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "phone text" }, [
                          _c("div", { staticClass: "left" }, [
                            _vm._v(" 备用电话 "),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.detail.alternatePhone)),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "icon",
                              class: { disabled: !_vm.detail.alternatePhone },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-phone",
                                staticStyle: { color: "white" },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "title item", staticStyle: { margin: "10px" } },
                [
                  _c(
                    "el-button-group",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-arrow-left",
                          },
                        },
                        [_vm._v("上一条")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-arrow-left",
                          },
                        },
                        [_vm._v("扔到公海")]
                      ),
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("下一条"),
                        _c("i", {
                          staticClass: "el-icon-arrow-right el-icon--right",
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              !!_vm.detail.id
                ? _c("record-form", { attrs: { info: _vm.detail } })
                : _vm._e(),
              _c("custom-form", { ref: "form", on: { ok: _vm.successChange } }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.tableList,
                    "row-class-name": _vm.tableRowClassName,
                  },
                  on: { "cell-click": _vm.showDetail },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "公司名称",
                      align: "left",
                      prop: "customerName",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "联系电话",
                      align: "right",
                      prop: "mobile",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }