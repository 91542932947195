var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.user, rules: _vm.rules, "label-width": "80px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "姓名", prop: "nickName" } },
        [
          _c("el-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.user.nickName,
              callback: function ($$v) {
                _vm.$set(_vm.user, "nickName", $$v)
              },
              expression: "user.nickName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }