//引入封装好的 service
import service from './axios';
 
//声明一个基础接口变量
//let baseURL = "http://vip.netcaa.com/api/";
//let baseURL = "/api";
// //配置开发环境
// if (process.env.NODE_ENV === 'development') {
//     baseURL = '';
// }
 
// // 配置生产环境
// if (process.env.NODE_ENV === 'production') {
//   baseURL = '';
// }
 
//设置请求头（如果请求头统一的话可以在axios文件设置，则无须从这里传过去）
// const header = {
//   Accept: 'application/json;charset=UTF-8',
// }
// const baseURL = "http://www.1qiyu.com/api";//http://120.26.193.103:911
let request = {
    //登录
    login(data) {
        return service({
          url: '/login',
          headers: {
            isToken: false
          },
          method: 'post',
          data
        })
    },
    //注册
    register (data) {
        return service({
            url: '/system/user/register',
            headers: {
                isToken: false
            },
            method: 'post',
            data
        })
    },
    // 确定
    confirmRole (data) {
        return service({
            url: '/system/user/isAdmin',
            headers: {
                isToken: false
            },
            method: 'post',
            data
        })
    },
    //短信验证码
    registerCode (data) {
        return service({
            url: '/system/user/registerCode',
            method: 'post',
            headers: {
                isToken: false
            },
            data
        })
    },
    //图片验证码
    captchaImage () {
        return service({
            url: '/captchaImage',
            headers: {
                isToken: false
            },
            method: 'get',
        })
    },
    //获取资源包
    getPacket(params) {
        return service({
            url: '/business/crmresourcefile/getPacket',
            method: 'get',
            headers: {
                isToken: false
            },
            params
        })
    },
    //获取标签列表
    getTagList(query) {
        return service({
            url: '/business/crmresourcefile/getTagList',
            method: 'get',
            headers: {
                isToken: false
            },
            query
        })
    },
    //购买资源包
    buyResource (params) {
        return service({
            url: '/business/crmresourcefile/buyResource',
            method: 'post',
            params
        })
    },
    //获取公众号二维码 /system/user/wx/check?senceId=***
    qrCode(params) {
        return service({
            url: '/system/user/wx/qrCode',
            headers: {
                isToken: false
            },
            method: 'get',
            params
        })
    },
    check(senceId) {
        return service({
            url: '/system/user/wx/check?senceId='+ senceId,
            method: 'get',
            headers: {
                isToken: false
            },
        })
    },
    //退出
    logout() {
        return service({
          url: '/logout',
          method: 'post'
        })
    },
    getExcel(url) {
        return service({
            url,
            method:"get",
            responseType: 'blob'
        })
    },
}
export default request