var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("tags-view"),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile" ? void 0 : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c(
                "span",
                { staticStyle: { display: "flex", "margin-top": "4px" } },
                [
                  _c("span", { staticClass: "username" }, [
                    _vm._v(_vm._s(_vm.name)),
                  ]),
                  _c("vs-avatar", {
                    attrs: { color: "rgb(59,222,200)" },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _vm._v(" " + _vm._s(_vm.name.slice(-3)) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/profile" } },
                    [_c("el-dropdown-item", [_vm._v("个人中心")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.setting = true
                        },
                      },
                    },
                    [_c("span", [_vm._v("布局设置")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "qiyu_home", on: { click: _vm.logout } }, [
            _vm._v("退出登录"),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }