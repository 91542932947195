import Cookies from "js-cookie";

const TokenKey = "Admin-Token";
const GroupKey = "group";
import { domain, domainUrl } from "@/utils/setting";
export function getToken() {
    return localStorage.getItem(TokenKey)
    // return Cookies.get(TokenKey);
}

export function setToken(token) {
    // console.log('setToken', token)
    localStorage.setItem(TokenKey, token)
    setCookie(TokenKey, token, 1000)
    Cookies.set(TokenKey, token, { expires: 1000, domain });
    return;
}
// 设置cookie
function setCookie(name, value, daysToLive) {
    let cookie = name + "=" + encodeURIComponent(value);
    if (typeof daysToLive === "number") {
        cookie += "; max-age=" + (daysToLive * 24 * 60 * 60); // max-age单位是秒
    }
    document.cookie = cookie;
}

// 获取cookie
function getCookie(name) {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
        const [cookieName, cookieValue] = cookies[i].split("=");
        if (name === cookieName) {
            return decodeURIComponent(cookieValue);
        }
    }
    return "";
}

export function removeToken() {
    localStorage.removeItem(TokenKey)
    clearCookie()
    Cookies.remove(TokenKey, { domain })
    if (window.aegis) {
        window.aegis.infoAll({
            msg: 'pc-token是否清除：' + window.localStorage.getItem(TokenKey),
            trace: 'trace',
        });
    }
    return;
}
function clearCookie() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
export function getGroup() {
    return Cookies.get(GroupKey);
}