import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import ParentView from '@/components/ParentView';

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: (resolve) => require(['@/views/redirect'], resolve)
            }
        ]
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        hidden: true
    },
    {
        path: '/sip',
        component: () => import('@/views/qrcall'),
        hidden: true
    },
    {
        path: '/register',
        component: () => import('@/views/register'),
        hidden: true
    },
    {
        path: '/signin',
        component: () => import('@/views/signin'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/error/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error/401'),
        hidden: true
    },
    {
        path: '',
        component: Layout,
        redirect: 'index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/index'),
                name: '首页',
                meta: { title: '首页', icon: 'guide', noCache: true, affix: true }
            },
        ]
    },
    // {
    //   component: Layout,
    //   name: "DataPackage",
    //   hidden: false,
    //   alwaysShow: true,
    //   path: "/dataPackage",
    //   redirect: "noRedirect",
    //   meta: {title: "数据包", icon: "documentation", noCache: false},
    //   children: [
    //       {
    //           path: '/dataManage',
    //           component: (resolve) => require(['@/views/dataManage/index'], resolve),
    //           name: 'DataManage',
    //           meta: { title: '数据包管理', icon: 'dashboard', noCache: true, affix: true }
    //       },
    //       {
    //           path: '/labelManage',
    //           component: (resolve) => require(['@/views/dataManage/labelManage'], resolve),
    //           name: 'LabelManage',
    //           meta: { title: '标签管理', icon: 'dashboard', noCache: true, affix: true }
    //       },
    //   ]
    // },
    //   {
    //     path: '',
    //     component: Layout,
    //     redirect: 'index',
    //     children: [
    //       {
    //         path: 'index',
    //         component: (resolve) => require(['@/views/index'], resolve),
    //         name: '首页',
    //         meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true }
    //       }
    //     ]
    //   },
    {
        path: '/user',
        component: Layout,
        hidden: true,
        redirect: 'noredirect',
        children: [
            {
                path: 'profile',
                component: () => import('@/views/system/user/profile/index'),
                name: 'Profile',
                meta: { title: '个人中心', icon: 'user' }
            }
        ]
    },
    {
        path: '/dict',
        component: Layout,
        hidden: true,
        children: [
            {
                path: 'type/data/:dictId(\\d+)',
                component: () => import('@/views/system/dict/data'),
                name: 'Data',
                meta: { title: '字典数据', icon: '' }
            }
        ]
    },
    {
        path: '/custom',
        component: Layout,
        hidden: true,
        children: [
            {
                path: 'detail/:id(\\d+)',
                component: () => import('@/views/custom/source/Detail'),
                name: 'custom-detail',
                meta: { title: '客户详情', icon: '' }
            },
            {
                path: 'opensea',
                component: () => import('@/views/custom/opensea/index'),
                name: 'custom-detail',
                meta: { title: '公海客户', icon: '' }
            },
            {
                path: 'assigned',
                component: () => import('@/views/custom/assigned/index'),
                name: 'custom-detail',
                meta: { title: '已分配资源', icon: '' }
            },
            {
                path: 'tao',
                component: () => import('@/views/custom/tao/index'),
                name: 'custom-detail',
                meta: { title: '淘客户', icon: '' }
            }
        ]
    },
    {
        path: '/job',
        component: Layout,
        hidden: true,
        children: [
            {
                path: 'log',
                component: () => import('@/views/monitor/job/log'),
                name: 'JobLog',
                meta: { title: '调度日志' }
            },

        ]
    },
    {
        path: '/gen',
        component: Layout,
        hidden: true,
        children: [
            {
                path: 'edit/:tableId(\\d+)',
                component: () => import('@/views/tool/gen/editTable'),
                name: 'GenEdit',
                meta: { title: '修改生成配置' }
            },

        ]
    }
]

export default new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})
