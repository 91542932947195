var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "记录表",
            visible: _vm.visible,
            width: "1200px",
            top: "5vh",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-table",
                {
                  ref: "table",
                  attrs: { data: _vm.dbTableList, height: "260px" },
                  on: {
                    "row-click": _vm.clickRow,
                    "selection-change": _vm.handleSelectionChange,
                  },
                },
                [
                  _c("el-table-column", { attrs: { prop: "id", width: "55" } }),
                  _c("el-table-column", {
                    attrs: { prop: "contactName", label: "联系人" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "contactType", label: "联系方式" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "contactNote", label: "联系小记" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "remark", label: "标签" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "联系时间",
                      align: "center",
                      prop: "contactTime",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.parseTime(scope.row.contactTime))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  icon: "el-icon-edit",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpdate(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  icon: "el-icon-delete",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "contactName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入联系人" },
                            model: {
                              value: _vm.form.contactName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contactName", $$v)
                              },
                              expression: "form.contactName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系方式", prop: "contactType" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入联系方式" },
                            model: {
                              value: _vm.form.contactType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contactType", $$v)
                              },
                              expression: "form.contactType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系时间", prop: "contactTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "date",
                              placeholder: "选择时间",
                            },
                            model: {
                              value: _vm.form.contactTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contactTime", $$v)
                              },
                              expression: "form.contactTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入标签" },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系小记", prop: "contactNote" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入小记",
                            },
                            model: {
                              value: _vm.form.contactNote,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contactNote", $$v)
                              },
                              expression: "form.contactNote",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.open = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }