/*
 * @Author: your name
 * @Date: 2021-06-02 10:55:38
 * @LastEditTime: 2021-07-18 21:52:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /call_manage_system_vue/src/store/index.js
 */
import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import user from "./modules/user";
import tagsView from "./modules/tagsView";
import permission from "./modules/permission";
import settings from "./modules/settings";
import getters from "./getters";
import websocket from "./modules/websocket";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    tagsView,
    permission,
    settings,
    websocket
  },
  getters,
});

export default store;
