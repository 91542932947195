var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
      style: {
        backgroundColor:
          _vm.sideTheme === "theme-dark"
            ? _vm.variables.menuBg
            : _vm.variables.menuLightBg,
      },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _vm.logo
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: _vm.logo },
                      })
                    : _c(
                        "h1",
                        {
                          staticClass: "sidebar-title",
                          style: {
                            color:
                              _vm.sideTheme === "theme-dark"
                                ? _vm.variables.sidebarTitle
                                : _vm.variables.sidebarLightTitle,
                          },
                        },
                        [_vm._v(_vm._s(_vm.title) + " ")]
                      ),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _vm.logo
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: _vm.logo },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "sidebar-title-body" }, [
                    _c(
                      "h1",
                      {
                        staticClass: "sidebar-title-short",
                        style: {
                          color:
                            _vm.sideTheme === "theme-dark"
                              ? _vm.variables.sidebarTitle
                              : _vm.variables.sidebarLightTitle,
                        },
                      },
                      [_vm._v(_vm._s(_vm.title) + " ")]
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }