/*
 * @Author: your name
 * @Date: 2021-03-19 15:15:54
 * @LastEditTime: 2021-06-14 22:39:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /call_manage_system_vue/src/components/Verifition/api/index.js
 */
import request from '@/utils/request'

//获取验证图片
export function reqGet(data) {
    return request({
        url: '/captcha/get',
        method: 'post',
        data
    })
}

//滑动或者点选验证
export function reqCheck(data) {
    return request({
        url: '/captcha/check',
        method: 'post',
        data
    })
}
