var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "register-box",
          style: { backgroundImage: "url(" + _vm.imageUrl + ")" },
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "register-content" }, [
            _vm._m(1),
            !_vm.showRoleBox
              ? _c("div", { staticClass: "right-res-box" }, [
                  _vm.loginType !== 3
                    ? _c("div", { staticClass: "res-login-box" }, [
                        _c("div", { staticClass: "head-tab" }, [
                          _c(
                            "div",
                            {
                              staticClass: "login-btn",
                              class: { active: _vm.loginType === 1 },
                              on: {
                                click: function ($event) {
                                  return _vm.changeType(1)
                                },
                              },
                            },
                            [_vm._v("用户登录 ")]
                          ),
                          _c(
                            "div",
                            {
                              class: { active: _vm.loginType === 2 },
                              on: {
                                click: function ($event) {
                                  return _vm.changeType(2)
                                },
                              },
                            },
                            [_vm._v("用户注册")]
                          ),
                        ]),
                        _vm.loginType === 2
                          ? _c(
                              "div",
                              { staticClass: "login-content" },
                              [
                                _c("CusInput", {
                                  attrs: { verify: _vm.loginVerify },
                                  on: { handleVerify: _vm.handleRegVerify },
                                  model: {
                                    value: _vm.regObj.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.regObj, "phone", $$v)
                                    },
                                    expression: "regObj.phone",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "verification-box" },
                                  [
                                    _c("CusInput", {
                                      attrs: {
                                        verify: _vm.picVerify,
                                        maxlength: 4,
                                        placeholder: "请输入图片验证码",
                                      },
                                      on: { handleVerify: _vm.handlePicVerify },
                                      model: {
                                        value: _vm.regObj.picValidateCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.regObj,
                                            "picValidateCode",
                                            $$v
                                          )
                                        },
                                        expression: "regObj.picValidateCode",
                                      },
                                    }),
                                    _c("div", { staticClass: "img-btn" }, [
                                      _vm.imgcode
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.imgcode,
                                              alt: "",
                                            },
                                            on: { click: _vm.captchaImage },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "code-box" },
                                  [
                                    _c("CusInput", {
                                      attrs: {
                                        verify: _vm.codeVerify,
                                        maxlength: 6,
                                        placeholder: "请输入短信验证码",
                                      },
                                      on: {
                                        handleVerify: _vm.handleCodeVerify,
                                      },
                                      model: {
                                        value: _vm.regObj.phoneValidateCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.regObj,
                                            "phoneValidateCode",
                                            $$v
                                          )
                                        },
                                        expression: "regObj.phoneValidateCode",
                                      },
                                    }),
                                    _c("div", { staticClass: "get-code-btn" }, [
                                      _vm.timeTrue === true
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "code-btn",
                                              on: { click: _vm.getVerCode },
                                            },
                                            [_vm._v("获取验证码")]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.time) + "s后重发"
                                            ),
                                          ]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("cus-button", {
                                  attrs: {
                                    isLoading: _vm.showRegLoading,
                                    type: "large-button",
                                    text: "注册",
                                  },
                                  on: { click: _vm.goRegister },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "agreement-box" },
                                  [
                                    _c("el-checkbox", {
                                      model: {
                                        value: _vm.argument,
                                        callback: function ($$v) {
                                          _vm.argument = $$v
                                        },
                                        expression: "argument",
                                      },
                                    }),
                                    _c("div", { staticClass: "agreement" }, [
                                      _vm._v(" 我已阅读并同意 "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleShowArge(1)
                                            },
                                          },
                                        },
                                        [_vm._v("《用户协议》")]
                                      ),
                                      _vm._v(" 和 "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleShowArge(2)
                                            },
                                          },
                                        },
                                        [_vm._v("《隐私政策》")]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.loginType === 1
                          ? _c(
                              "div",
                              { staticClass: "login-content" },
                              [
                                _c("CusInput", {
                                  attrs: { verify: _vm.loginVerify },
                                  on: { handleVerify: _vm.handleLogVerify },
                                  model: {
                                    value: _vm.loginBoj.username,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.loginBoj, "username", $$v)
                                    },
                                    expression: "loginBoj.username",
                                  },
                                }),
                                _c("CusInput", {
                                  attrs: {
                                    type: "password",
                                    placeholder: "请输入密码",
                                  },
                                  model: {
                                    value: _vm.loginBoj.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.loginBoj, "password", $$v)
                                    },
                                    expression: "loginBoj.password",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "login-state" },
                                  [
                                    _c("el-checkbox", {
                                      model: {
                                        value: _vm.holdLogin,
                                        callback: function ($$v) {
                                          _vm.holdLogin = $$v
                                        },
                                        expression: "holdLogin",
                                      },
                                    }),
                                    _c("span", [_vm._v(" 保持登录")]),
                                  ],
                                  1
                                ),
                                _c("cus-button", {
                                  attrs: {
                                    isLoading: _vm.showLoginLoading,
                                    type: "large-button",
                                    text: "登录",
                                  },
                                  on: { click: _vm.goLogin },
                                }),
                                _c("div", { staticClass: "agreement-box" }, [
                                  _c(
                                    "div",
                                    { staticClass: "agreement logn-agre" },
                                    [
                                      _vm._v(" 登录即表示已同意 "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleShowArge(1)
                                            },
                                          },
                                        },
                                        [_vm._v("《用户协议》")]
                                      ),
                                      _vm._v(" 和 "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleShowArge(2)
                                            },
                                          },
                                        },
                                        [_vm._v("《隐私政策》")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "wx-login-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "wx-login",
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeType(3)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/img/register/wx_2.png"),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.loginType === 3
                    ? _c("div", { staticClass: "wx-login-box" }, [
                        _c("div", { staticClass: "head-title" }, [
                          _vm._v(" 微信扫码登录 "),
                        ]),
                        _c("div", { staticClass: "hint" }, [
                          _vm._v("未注册的微信号将自动创建企鱼账号"),
                        ]),
                        _c("div", { staticClass: "img-box" }, [
                          _c(
                            "div",
                            { staticClass: "wx-qrcode" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "refresh",
                                  on: { click: _vm.qrCode },
                                },
                                [_c("i")]
                              ),
                              _c("CusLoading", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showQrLoading,
                                    expression: "showQrLoading",
                                  },
                                ],
                              }),
                              _c("img", {
                                staticClass: "code-img",
                                attrs: { src: _vm.qrUrl, alt: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._m(2),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "reg-login-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.changeType(1)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/register/phone.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("账号登录/注册")]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "agreement-box" }, [
                          _c("div", { staticClass: "agreement logn-agre" }, [
                            _vm._v(" 扫码登录即表示已阅读并同意 "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleShowArge(1)
                                  },
                                },
                              },
                              [_vm._v("《用户协议》")]
                            ),
                            _vm._v(" 和 "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleShowArge(2)
                                  },
                                },
                              },
                              [_vm._v("《隐私政策》")]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.showRoleBox
              ? _c("div", { staticClass: "right-res-box" }, [
                  _c("div", { staticClass: "res-login-box" }, [
                    _c("div", { staticClass: "role-text" }, [
                      _vm._v("请选择您的角色"),
                    ]),
                    _c("div", { staticClass: "head-tab" }, [
                      _c(
                        "div",
                        {
                          staticClass: "login-btn",
                          class: { active: _vm.roleType === 1 },
                          on: {
                            click: function ($event) {
                              return _vm.changeRole(1)
                            },
                          },
                        },
                        [_vm._v("我是管理员 ")]
                      ),
                      _c(
                        "div",
                        {
                          class: { active: _vm.roleType === 2 },
                          on: {
                            click: function ($event) {
                              return _vm.changeRole(2)
                            },
                          },
                        },
                        [_vm._v("我是员工")]
                      ),
                    ]),
                    _vm.roleType === 1
                      ? _c(
                          "div",
                          { staticClass: "login-content" },
                          [
                            _c("CusInput", {
                              attrs: {
                                placeholder: "请输入姓名",
                                verify: _vm.nickNameVerify,
                              },
                              on: { handleVerify: _vm.handleNickNameVerify },
                              model: {
                                value: _vm.roleObj1.nickName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.roleObj1, "nickName", $$v)
                                },
                                expression: "roleObj1.nickName",
                              },
                            }),
                            _c("CusInput", {
                              attrs: {
                                placeholder: "请输入公司名称",
                                verify: _vm.companyNameVerify,
                              },
                              on: { handleVerify: _vm.handleComNameVerify },
                              model: {
                                value: _vm.roleObj1.companyName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.roleObj1, "companyName", $$v)
                                },
                                expression: "roleObj1.companyName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.roleType === 2
                      ? _c(
                          "div",
                          { staticClass: "login-content" },
                          [
                            _c("CusInput", {
                              attrs: {
                                placeholder: "请输入姓名",
                                verify: _vm.nickNameVerify2,
                              },
                              on: { handleVerify: _vm.handleNickNameVerify2 },
                              model: {
                                value: _vm.roleObj2.nickName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.roleObj2, "nickName", $$v)
                                },
                                expression: "roleObj2.nickName",
                              },
                            }),
                            _c("CusInput", {
                              attrs: {
                                placeholder: "请输入公司编码",
                                verify: _vm.companyCodeVerify,
                              },
                              on: { handleVerify: _vm.handleComCodeVerify },
                              model: {
                                value: _vm.roleObj2.companyCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.roleObj2, "companyCode", $$v)
                                },
                                expression: "roleObj2.companyCode",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "comfirm-btn" },
                      [
                        _c("cus-button", {
                          attrs: {
                            isLoading: _vm.showConfirmLoading,
                            type: "large-button",
                            text: "确定",
                          },
                          on: { click: _vm.handleConfirm },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c("AgreementDialog", {
        attrs: { type: _vm.typeArg, isShow: _vm.isShow },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "head" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/register/logo_2.png"), alt: "" },
      }),
      _c("span", { staticClass: "title" }, [_vm._v("企鱼")]),
      _c("span", { staticClass: "line" }, [_vm._v("|")]),
      _c("span", { staticClass: "min-title" }, [_vm._v("好业绩   用企鱼")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left-title" }, [
      _c("h2", [_vm._v("企鱼")]),
      _c("p", [_vm._v("让你的业绩游起来")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "qr-code-hont" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/register/wx_2.png"), alt: "" },
      }),
      _c("span", [_vm._v("微信扫码")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }