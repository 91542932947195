var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "el-loading-mask" }, [
    _c("div", { staticClass: "el-loading-spinner" }, [
      _c(
        "svg",
        { staticClass: "circular", attrs: { viewBox: "25 25 50 50" } },
        [
          _c("circle", {
            staticClass: "path",
            attrs: { cx: "50", cy: "50", r: "20", fill: "none" },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }