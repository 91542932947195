var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "test-sip" },
    [
      _c("el-switch", {
        attrs: { "active-text": "打开日志", "inactive-text": "关闭日志" },
        model: {
          value: _vm.logFlag,
          callback: function ($$v) {
            _vm.logFlag = $$v
          },
          expression: "logFlag",
        },
      }),
      _c("div", { staticClass: "step" }, [
        _c("h2", [_vm._v("步骤 1：输入自己的分机号（1001-1019）")]),
        _c(
          "div",
          { staticClass: "step-box" },
          [
            _c("el-input", {
              staticClass: "input-box",
              attrs: {
                placeholder: "请输入自己的分机号（1001-1010）",
                disabled: _vm.localStream !== null,
              },
              model: {
                value: _vm.userExtension,
                callback: function ($$v) {
                  _vm.userExtension = $$v
                },
                expression: "userExtension",
              },
            }),
            _c(
              "el-button",
              {
                staticClass: "step-button",
                attrs: {
                  type: "primary",
                  disabled: !_vm.userExtension || _vm.isRegisted,
                },
                on: { click: _vm.registerUser },
              },
              [_vm._v(" 注册 ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "step" }, [
        _c("h2", [_vm._v("步骤 2：输入要呼叫的分机号（1001-1019）")]),
        _c(
          "div",
          { staticClass: "step-box" },
          [
            _c("el-input", {
              staticClass: "input-box",
              attrs: {
                placeholder: "请输入要呼叫的分机号（1001-1010）",
                disabled: !_vm.isRegisted,
              },
              model: {
                value: _vm.targetExtension,
                callback: function ($$v) {
                  _vm.targetExtension = $$v
                },
                expression: "targetExtension",
              },
            }),
            _c(
              "el-button",
              {
                staticClass: "step-button",
                attrs: {
                  type: "primary",
                  disabled: !_vm.targetExtension || _vm.currentSession !== null,
                },
                on: {
                  click: function ($event) {
                    return _vm.startCall(false)
                  },
                },
              },
              [_vm._v(" 拨打语音电话 ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "step-button",
                attrs: {
                  type: "primary",
                  disabled: !_vm.targetExtension || _vm.currentSession !== null,
                },
                on: {
                  click: function ($event) {
                    return _vm.startCall(true)
                  },
                },
              },
              [_vm._v(" 拨打视频电话 ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "step" }, [
        _c("h2", [_vm._v("其他操作")]),
        _c(
          "div",
          { staticClass: "step-box" },
          [
            _c(
              "el-button",
              {
                staticClass: "step-button",
                attrs: {
                  type: "primary",
                  disabled: _vm.currentSession == null,
                },
                on: { click: _vm.hangUpCall },
              },
              [_vm._v(" 挂断 ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "step-button",
                attrs: { type: "primary", disabled: !_vm.isRegisted },
                on: { click: _vm.unregisterUser },
              },
              [_vm._v(" 取消注册 ")]
            ),
            !_vm.localStream
              ? _c(
                  "el-button",
                  {
                    staticClass: "step-button",
                    attrs: {
                      type: "primary",
                      disabled: _vm.currentSession !== null,
                    },
                    on: { click: _vm.captureLocalMedia },
                  },
                  [_vm._v(" 测试本地设备 ")]
                )
              : _c(
                  "el-button",
                  {
                    staticClass: "step-button",
                    attrs: { type: "primary", disabled: _vm.currentSession },
                    on: { click: _vm.stopLocalMedia },
                  },
                  [_vm._v(" 停止测试本地设备 ")]
                ),
          ],
          1
        ),
      ]),
      _vm._m(0),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step" }, [
      _c("h2", [_vm._v("音频：")]),
      _c("div", { staticClass: "step-box" }, [
        _c("audio", { attrs: { id: "audio", autoplay: "" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step" }, [
      _c("h2", [_vm._v("视频：")]),
      _c("div", { staticClass: "step-box" }, [
        _c("video", {
          attrs: { id: "meVideo", playsinline: "", autoplay: "" },
        }),
        _c("video", {
          attrs: { id: "remoteVideo", playsinline: "", autoplay: "" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }