<template>
    <div class="login">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
            <h3 class="title">企鱼</h3>
            <el-form-item prop="username">
                <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">
                    <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
                </el-input>
            </el-form-item>

            <el-form-item prop="password">

                <el-input v-model="loginForm.password" type="text" auto-complete="off" placeholder="密码"
                    @keyup.enter.native="handleLogin">
                    <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
                </el-input>
            </el-form-item>

            <el-form-item class="code_content">
                <el-input v-model="IMG_CODE_USER_INPUT" @blur="checkCode" placeholder="图形验证码" class="captchaCode">
                    <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
                    /></el-input>
                <div class="code" @click="refresh" style="
            height: 38px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
            cursor: pointer;
          ">
                    <vue-captcha ref="captcha" :height="38" :width="120" :captcha.sync="IMG_CODE"
                        @on-change="handleChange"></vue-captcha>
                </div>
            </el-form-item>
            <el-checkbox v-model="loginForm.rememberMe" style="margin: 0px 0px 25px 0px">记住密码</el-checkbox>
            <el-form-item style="width: 100%">
                <el-button :loading="loading" type="primary" style="width: 100%" @click.native.prevent="acquire">
                    <span v-if="!loading">登 录</span>
                    <span v-else>登 录 中...</span>
                </el-button>
            </el-form-item>
        </el-form>
        <!--  底部  -->
        <div class="el-login-footer">
            <span>Copyright ©2013-2023浙江网看创享科技有限公司 企鱼 |
                浙ICP备18020380号-4</span>
        </div>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import VueCaptcha from "vue-captcha-code";
import vconsole from "vconsole";
export default {
    name: "Login",
    data() {
        return {
            codeUrl: "",
            IMG_CODE: "",
            cookiePassword: "",
            captchaCode: "",
            IMG_CODE_USER_INPUT: "",
            loginForm: {
                username: "",
                password: "123456",
                rememberMe: false,
                // code: "",
                // uuid: "",
            },
            loginRules: {
                username: [
                    { required: true, trigger: "blur", message: "用户名不能为空" },
                ],
                password: [
                    { required: true, trigger: "blur", message: "密码不能为空" },
                ],
            },
            loading: false,
            redirect: undefined,
        };
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true,
        },
    },
    created() {
        this.getCookie();
    },
    components: {
        VueCaptcha,
    },
    methods: {
        checkCode() { },
        refresh() { },
        handleChange() { },
        getCookie() {
            const username = Cookies.get("username");
            const password = Cookies.get("password");
            const rememberMe = Cookies.get("rememberMe");
            this.loginForm = {
                username: username === undefined ? this.loginForm.username : username,
                password:
                    password === undefined ? this.loginForm.password : decrypt(password),
                rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
            };
        },
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    if (this.loginForm.rememberMe) {
                        Cookies.set("username", this.loginForm.username, { expires: 30 });
                        Cookies.set("password", encrypt(this.loginForm.password), {
                            expires: 30,
                        });
                        Cookies.set("rememberMe", this.loginForm.rememberMe, {
                            expires: 30,
                        });
                    } else {
                        Cookies.remove("username");
                        Cookies.remove("password");
                        Cookies.remove("rememberMe");
                    }
                    this.$store
                        .dispatch("Login", this.loginForm)
                        .then(() => {
                            this.$router.push({ path: this.redirect || "/" }).catch(() => { });
                        })
                        .catch(() => {
                            this.loading = false;
                            // this.getCode();
                        });
                }
            });
        },
        acquire() {
            // 登录验证码
            if (this.IMG_CODE_USER_INPUT !== this.IMG_CODE) {
                this.$message.error("图形验证码输入有误，请重新输入");
                setTimeout(() => {
                    this.IMG_CODE_USER_INPUT = "";
                }, 1500);
                return;
            }
            this.handleLogin();
        },
    },
};
</script>

<style scoped rel="stylesheet/scss" lang="scss">
.captchaCode {
    width: calc(100% - 140px);
}

.code {
    display: inline-block;
}

.code canvas {
    vertical-align: middle;
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url("../assets/images/login-background.jpg");
    background-size: cover;
}

.title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
}

.login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 400px;
    padding: 25px 25px 5px 25px;

    .el-input {
        height: 38px;

        input {
            height: 38px;
        }
    }

    .input-icon {
        height: 39px;
        width: 14px;
        margin-left: 2px;
    }
}

.login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
}

.login-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
        cursor: pointer;
        vertical-align: middle;
    }
}

.el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;

    span {
        text-align: center;
        font-size: 14px;
        border-bottom: 1px solid #dbdbdb;
        margin-bottom: 88px;
        margin-top: 68px;
        padding: 12px 118px;
    }
}

.login-code-img {
    height: 38px;
}
</style>
